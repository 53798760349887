
import { FormBlock } from "@/components/FormBuilder/types";
import { documentCategoryForm } from "@/forms/admin/documentCategory";
import FormBuilder from "@/components/FormBuilder/FormBuilder.vue";
import Vue from "vue";
import * as types from "@/store/mutation-types";
import {
  atlasFileCategoryMapActions,
  atlasFileCategoryMapMutations,
  atlasFileCategoryMapState
} from "@/store/modules/atlasFileCategory";

interface ICreateCategory {
  errorMessage: string;
  successMessage: string;
  validationData: Record<string, any>;
  loadingText: string;
}
export default Vue.extend({
  name: "AddAtlasFileCategory",
  components: { FormBuilder },
  data(): ICreateCategory {
    return {
      errorMessage: "",
      successMessage: "",
      validationData: {},
      loadingText: "Submitting new category data. Please wait..."
    };
  },
  created() {
    this.SET_EDIT({} as any);
  },
  methods: {
    ...atlasFileCategoryMapActions(["createAtlasFileCategory"]),
    ...atlasFileCategoryMapMutations(["SET_EDIT"]),
    ...atlasFileCategoryMapMutations({ editField: types.SET_EDIT_FIELD }),
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.submit();
          break;
        case "cancel":
          this.cancelCallback();
          break;
      }
    },
    cancelCallback() {
      this.$router.back();
    },
    async submit(): Promise<void> {
      try {
        if (this.formIsValid) {
          const fieldData = {
            ...this.editing,
            createdBy: this.$getCurrentUser._id
          };
          await this.createAtlasFileCategory(fieldData).then(() => {
            this.successMessage = "Category created successfully";
            this.$router.back();
          });
        } else {
          this.errorMessage = "Please fill in all required fields";
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...atlasFileCategoryMapState(["editing", "makingApiRequest"]),
    formFields(): FormBlock[] {
      return documentCategoryForm;
    },
    formIsValid(): boolean {
      return !!this.validationData.formIsValid;
    }
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  }
});
