import {
  useFormFieldGroup,
  useFormSection,
  useTextField
} from "@/components/FormBuilder/Helpers";
import { FormBlock } from "@/components/FormBuilder/types";
import { useField } from "../../components/FormBuilder/Helpers/fields/useField";

export const documentCategoryForm: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup(
      [
        useTextField({
          key: "title",
          label: "Title",
          placeholder: "Title",
          required: true,
          validations: [
            {
              method: "custom",
              error: "Title shouldn't have any special characters",
              customValidator: (value: any) => {
                const regex = new RegExp("^[a-zA-Z0-9 ]+$");
                if (!regex.test(value)) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        })
      ],
      { layout: "full" }
    ),
    ...useFormFieldGroup(
      [
        useField({
          key: "description",
          label: "Description",
          type: "textarea",
          required: false
        })
      ],
      { layout: "full" }
    )
  ])
];
